body {
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  scrollbar-gutter: stable both-edges;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
strong,
th,
td,
input,
label,
a {
  /*line-height: normal !important;*/
  outline: 0;
}

img[alt]:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: attr(alt);
}

.ql-root blockquote {
 margin: 0.5em 20px 0.5em 10px !important;
 padding: 0.5em 0 0.5em 10px !important;
 border-left: 4px solid #E5E5E5;
}

.ql-root pre.ql-syntax {
 background-color: #3A3C4F;
 color: #F0F0F0;
 overflow: hidden;
 white-space: pre-wrap;
 padding: 4px 8px;
 margin: 4px 0;
 display: block;
 border-radius: 5px;
}
.ql-root a {
 color: #4098F4;
 text-decoration: none;
}
.ql-root p, .ql-root h1, .ql-root h2, .ql-root h3, .ql-root ul, .ql-root ol, .ql-root blockquote, .ql-root pre {
  width: inherit;
}
.ql-root p, .ql-root a {
  line-height: 1.42;
}
.ql-root p, .ql-root a, .wrap-text {
  font-size: inherit;
  word-break: break-word;
  overflow: hidden;
}

.ql-root ol, .ql-root ul {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ql-root h1, .ql-root h2, .ql-root h3 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ql-container {
  font-size: inherit !important;
  color: inherit !important;
  font-weight: inherit !important;
  font-family: 'Mulish', sans-serif;
}

.ql-editor {
  padding: 0 !important;
  white-space: pre-wrap;
}

.ql-clipboard {
  white-space: pre-wrap;
}

.ql-editor.ql-blank::before {
  left: 0 !important;
  right: 0 !important;
  opacity: .5;
}

.ql-ellipsis p, .ql-ellipsis h1, .ql-ellipsis h2, .ql-ellipsis h3 {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: initial !important;
}

.ql-mention-list-container {
  display: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #4098f438;
  padding: 2px 0;
  margin-right: 4px;
  -webkit-user-select: all;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

.jexcel_content {
  padding: 0 !important;
}

.jexcel::selection, .jexcel td::selection, .jexcel_corner::selection {
  background: transparent;
}

.jexcel td {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.sortable-chosen {
  opacity: 1 !important;
}

.sortable-placeholder {
  opacity: 0 !important;
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
h1 {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
}
h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px !important;
  margin: 0;
}
h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.z-2 {
  z-index: 2 !important;
}

/** margin & padding global classes */
.-mx-0-5 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}
.-mx-1 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}
.-mx-2 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}
.-mx-2-5 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.-mx-3 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}
.-mr-3 {
  margin-right: -12px !important;
}
.-mx-4 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}
.-mx-6 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}
.-mx-8 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}
.-m-0-5 {
  margin: -2px !important;
}
.m-0 {
  margin: 0 !important;
}
.m-0-5 {
  margin: 2px !important;
}
.m-1 {
  margin: 4px !important;
}
.m-2 {
  margin: 8px !important;
}
.m-2-5 {
  margin: 10px !important;
}
.m-3 {
  margin: 12px !important;
}
.m-4 {
  margin: 16px !important;
}
.m-6 {
  margin: 24px !important;
}
.m-8 {
  margin: 32px !important;
}
.-mb-3 {
  margin-bottom: -12px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-0-5 {
  margin-bottom: 2px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-2-5 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 12px !important;
}
.mb-4 {
  margin-bottom: 16px !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
.mb-6 {
  margin-bottom: 24px !important;
}
.mb-8 {
  margin-bottom: 32px !important;
}
.mb-9 {
  margin-bottom: 36px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-0-5 {
  margin-left: 2px !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.ml-2-5 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 12px !important;
}
.ml-4 {
  margin-left: 16px !important;
}
.ml-6 {
  margin-left: 24px !important;
}
.ml-8 {
  margin-left: 32px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mr-2-5 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 12px !important;
}
.mr-4 {
  margin-right: 16px !important;
}
.mr-5 {
  margin-right: 20px !important;
}
.mr-6 {
  margin-right: 24px !important;
}
.mr-8 {
  margin-right: 32px !important;
}
.-mt-5 {
  margin-top: -20px !important;
}
.-mt-4 {
  margin-top: -16px !important;
}
.-mt-2 {
  margin-top: -8px !important;
}
.-mt-0-5 {
  margin-top: -2px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-2-5 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 12px !important;
}
.mt-4 {
  margin-top: 16px !important;
}
.mt-5 {
  margin-top: 20px !important;
}
.mt-6 {
  margin-top: 24px !important;
}
.mt-8 {
  margin-top: 32px !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-2-5 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mx-8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-0-5 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.my-1-5 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-2-5 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-0-5 {
  padding: 2px !important;
}
.p-1 {
  padding: 4px !important;
}
.p-2 {
  padding: 8px !important;
}
.p-2-5 {
  padding: 10px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-4 {
  padding: 16px !important;
}
.p-6 {
  padding: 24px !important;
}
.p-8 {
  padding: 32px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-2-5 {
  padding-bottom: 10px !important;
}
.pb-3 {
  padding-bottom: 12px !important;
}
.pb-4 {
  padding-bottom: 16px !important;
}
.pb-6 {
  padding-bottom: 24px !important;
}
.pb-8 {
  padding-bottom: 32px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 4px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.pl-2-5 {
  padding-left: 10px !important;
}
.pl-3 {
  padding-left: 12px !important;
}
.pl-4 {
  padding-left: 16px !important;
}
.pl-6 {
  padding-left: 24px !important;
}
.pl-8 {
  padding-left: 32px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pr-2-5 {
  padding-right: 10px !important;
}
.pr-3 {
  padding-right: 12px !important;
}
.pr-4 {
  padding-right: 16px !important;
}
.pr-6 {
  padding-right: 24px !important;
}
.pr-8 {
  padding-right: 32px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-0-5 {
  padding-top: 2px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-2-5 {
  padding-top: 10px !important;
}
.pt-3 {
  padding-top: 12px !important;
}
.pt-4 {
  padding-top: 16px !important;
}
.pt-4 {
  padding-top: 16px !important;
}
.pt-6 {
  padding-top: 24px !important;
}
.pt-8 {
  padding-top: 32px !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-0-5 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-2-5 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-1-5 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-2-5 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.font-initial {
  font-weight: initial !important;
}
.font-normal {
  font-weight: normal !important;
}
.font-lighter {
  font-weight: lighter !important;
}
.font-bold {
  font-weight: 600 !important;
}

.text-xs {
  font-size: 12px !important;
}
.text-sm {
  font-size: 14px !important;
}
.text-md {
  font-size: 16px !important;
}
.text-lg {
  font-size: 18px !important;
}
.text-xl {
  font-size: 20px !important;
}
.text-2xl {
  font-size: 24px !important;
}
.text-3xl {
  font-size: 28px !important;
}
.text-4xl {
  font-size: 32px !important;
}
.text-5xl {
  font-size: 48px !important;
}

.w-max {
  width: max-content !important;
}
.w-auto {
  width: auto !important;
}
.max-w-full {
  max-width: 100% !important;
}
.min-w-full {
  min-width: 100% !important;
}
.min-w-max {
  min-width: max-content !important;
}
.max-w-page {
  width: 1280px !important;
}
.w-full {
  width: 100% !important;
}
.w-8 {
  width: 32px !important;
}
.w-16 {
  width: 64px !important;
}

.h-0 {
  height: 0 !important;
}
.h-32 {
  height: 32px !important;
}
.h-36 {
  height: 36px !important;
}
.h-64 {
  height: 64px !important;
}
.h-max {
  height: max-content !important;
}
.h-full {
  height: 100% !important;
}
.min-h-full {
  min-height: 100% !important;
}
.min-h-max {
  min-height: max-content !important;
}
.child-h-9 > div  {
  height: 36px !important;
}

.opaque {
  opacity: 1 !important;
}
.transparent {
  opacity: 0 !important;
}
.semi-transparent {
  opacity: 0.4 !important;
}
.transparent-2 {
  opacity: 0.5 !important;
}
.transparent-3 {
  opacity: 0.75 !important;
}
.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-anchor {
  overflow-anchor: none !important
}
.display-block {
  display: block !important;
}
.hidden {
  display: none !important;
}
.invisible {
  visibility: hidden !important;
}

.squared {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 5px !important;
}
.rounded-2 {
  border-radius: 10px !important;
}
.circle {
  border-radius: 50% !important;
}

.shrink-0 {
  flex-shrink: 0;
}

.border-solid {
  border-width: 1px !important;
  border-style: solid !important;
}
.border-solid-2 {
  border-width: 2px !important;
  border-style: solid !important;
}
.border-dashed {
  border-width: 1px !important;
  border-style: dashed !important;
}
.border-dashed-2 {
  border-width: 2px !important;
  border-style: dashed !important;
}
.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-text {
  cursor: text !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.text-transform-none {
  text-transform: none !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.top-50 {
  top: 50% !important;
}

.r-0 {
  right: 0 !important;
}
.r-1 {
  right: 4px !important;
}

.transition {
  transition: .3s !important;
}

.rotate-0 {
  transform: rotate(0deg) !important;
}
.rotate-30 {
  transform: rotate(30deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}
.-rotate-180 {
  transform: rotate(-180deg) !important;
}
.rotate-270 {
  transform: rotate(270deg) !important;
}

.bg-transparent {
  background: transparent !important;
}

.items-center {
  align-items: center !important;
}

.no-basis {
  flex-basis: 0 !important;
}

.white-space-pre {
  white-space: pre;
}

.text-nowrap {
  text-wrap: nowrap !important;
}

.text-deco-none {
  text-transform: none !important;
}

.text-deco-upper {
  text-transform: uppercase !important;
}

.ignore-events {
  pointer-events: none !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-left {
  justify-content: flex-start !important;
}
.justify-right {
  justify-content: flex-end !important;
}
.justify-between {
  justify-content: space-between !important;
}